<template>
  <div style="height: 100%">
    <PrivacyDataModel />
  </div>
</template>

<script>
import { PrivacyDataModel } from '@/components/console/Index.vue';

export default {
  components: {
    PrivacyDataModel,
  },
};
</script>
